---
name: YouTube
label: You Tube
fields:
  videoId:
    type: text
    label: Video ID
    default: dQw4w9WgXcQ
slots:
  - bottom
---

<script>
import { defineSlice } from '#imports'

export default {
  name: 'YouTube',
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      videoId: 'dQw4w9WgXcQ',
    }
  },

  computed: {
    devise() {
      return Object.assign(
        {},
        { fields: this.props.fields, test: 2 },
        this.$voix,
      )
    },
  },

  onMounted() {
    defineSlice({
      name: {
        label: 'YouTube',
        group: 'Slices',
      },
      description: 'A YouTube video',
      fields: {
        videoId: {
          type: 'text',
          label: 'Video ID',
          default: 'dQw4w9WgXcQ',
        },
      },
    })
  },
}
</script>

<template>
  <div>
    <div class="flex justify-center items-center p-12">
      <div class="max-w-[900px] w-full rounded-2xl overflow-hidden">
        <iframe
          :src="`https://www.youtube.com/embed/${fields.videoId.text}`"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="w-full aspect-[16/9]"
        />
      </div>
    </div>
  </div>
</template>
