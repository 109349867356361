// composables/useEventBus.ts
import mitt from 'mitt'

interface ApplicationEvents {
  'voix:error': Error
}

const emitter = mitt<ApplicationEvents>()

export const useEvent = emitter.emit
export const useListen = emitter.on
