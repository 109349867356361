<script setup lang="ts">
import {
  computed,
  provide,
  ref,
  watch,
} from 'vue'
import { useAdminStore } from '@voix/store/adminStore'

import { useUtils } from '@voix/composables/useUtils'
import { useSlice } from '@voix/composables/useSlice'
import type { Fields } from '@voix/composables/useSlice'
import { useEvent } from '@voix/composables/useEventBus'

import { useDebounceFn, useWindowSize } from '@vueuse/core'

import type { ComputedRef, Ref } from 'vue'

import type {
  FieldInterface,
  SliceInterface,
  SliceSettingsInterface,
} from '@voix/types'
import { useSliceStore } from '@voix/store/sliceStore'
import {
  buildColorStyles,
  buildFlexStyles,
  buildGridStyles,
  buildMarginStyles,
  buildMaxWidthStyles,
  buildPaddingStyles,
} from '../../composables/useStyle'
import { findBreakpointSettings } from '../../composables/useBreakpoints'

import { usePageStore } from '../../store/pageStore'
import { useRuntimeConfig } from '#imports'

defineOptions({ inheritAttrs: false })
const props = defineProps<{
  slice: SliceInterface
}>()
// Import all the slices dynamically
// voixSliceTransform.ts will automatically add the import statements
// like `import { LazySlicesCircleSlideshow } from '#components'` to
// this file at the comment below

// DO NOT REMOVE THESE LINES WHERE WE DEFINE components
let components: Record<string, any> = {}

import { LazyExampleAnotherNewSlice } from '#components'
import { LazySlicesEndlessSlider } from '#components'
import { LazyExampleNewSlice } from '#components'
import { LazySlicesCircleSlideshow } from '#components'
import { LazySlicesDiagnostics } from '#components'
import { LazySlicesEndlessSlide } from '#components'
import { LazySlicesGalleryOfImages } from '#components'
import { LazySlicesParagraphWithGallery } from '#components'
import { LazySlicesStaffCard } from '#components'
import { LazySlicesYouTube } from '#components'
import { LazySlicesTestingContentTest } from '#components'
import { LazySlicesTestingFilesTests } from '#components'
import { LazySlicesTestingImageTest } from '#components'
import { LazySlicesTestingSlotsChildTwo } from '#components'
import { LazySlicesTestingSlotsChildOne } from '#components'
import { LazySlicesTestingMediaTests } from '#components'
import { LazySlicesTestingSlotsParent } from '#components'
import { LazySlicesVerticalTabSystemVerticalTabs } from '#components'

components = {
  LazyExampleAnotherNewSlice,
  LazySlicesEndlessSlider,
  LazyExampleNewSlice,
  LazySlicesCircleSlideshow,
  LazySlicesDiagnostics,
  LazySlicesEndlessSlide,
  LazySlicesGalleryOfImages,
  LazySlicesParagraphWithGallery,
  LazySlicesStaffCard,
  LazySlicesYouTube,
  LazySlicesTestingContentTest,
  LazySlicesTestingFilesTests,
  LazySlicesTestingImageTest,
  LazySlicesTestingSlotsChildTwo,
  LazySlicesTestingSlotsChildOne,
  LazySlicesTestingMediaTests,
  LazySlicesTestingSlotsParent,
  LazySlicesVerticalTabSystemVerticalTabs
}


const resolvedComponent = components[`Lazy${props.slice.component}`]

const { isTopLevelWindow } = useUtils()
const sliceId = computed(() => {
  return `voix-slice-${props.slice.id}`
})

const pageStore = usePageStore()
const adminStore = useAdminStore()
const sliceStore = useSliceStore()
const sliceHelper = useSlice()
const sliceConfig = sliceStore.getSlice(props.slice.component)

const fieldsProxy: Ref<Fields | null> = ref(null)

// Field Data
if (sliceConfig && props.slice.fields)
  fieldsProxy.value = sliceHelper.resolveFields(sliceConfig, props.slice.fields)

// We need to watch the fields for edits so that we can update the
// fieldsProxy value. This is necessary because we need to be able to
// be reactive to changes in the fields.
watch(
  () => props.slice,
  () => {
    if (sliceConfig && props.slice.fields)
      fieldsProxy.value = sliceHelper.resolveFields(sliceConfig, props.slice.fields)
  },
  { deep: true },
)
if (import.meta.hot) {
  import.meta.hot.on('voix-slice-update', (data) => {
    const key = Object.keys(data)[0]
    if (key === props.slice.component && sliceConfig && props.slice.fields) {
      setTimeout(() => {
        console.log('updating proxy value', props.slice.component, props.slice.fields)
        fieldsProxy.value = sliceHelper.resolveFields(sliceConfig, props.slice.fields)
      }, 1000)
    }
  })
}

// Slice Styles
const sliceStyles = ref({})
function generateSliceStyles() {
  let theSlice = Object.assign({}, props.slice)

  if (props.slice.id)
    theSlice = pageStore.findElement(props.slice.id)?.element as SliceInterface

  if (!theSlice)
    return { properties: { settings: {} } }

  let styles: Record<string, string | number> = {}

  if (theSlice.properties && theSlice.properties.settings) {
    const breakpointSettings = findBreakpointSettings(
      theSlice.properties.settings,
    ) as SliceSettingsInterface | null

    if (breakpointSettings) {
      if (breakpointSettings.position)
        styles.position = breakpointSettings.position

      styles = {
        ...styles,
        ...buildFlexStyles(styles, breakpointSettings),
        ...buildGridStyles(styles, breakpointSettings),
        ...buildMaxWidthStyles(styles, breakpointSettings),
        ...buildMarginStyles(styles, breakpointSettings),
        ...buildPaddingStyles(styles, breakpointSettings),
        ...buildColorStyles(styles, breakpointSettings),
      }
    }
  }

  sliceStyles.value = styles
  return true
}

// Initial generation of styles
generateSliceStyles()

// Debounced updates to styles
const updateSliceStyles = useDebounceFn(
  generateSliceStyles,
  10,
  { maxWait: 50 },
)

// Reset styles on window resize
const { width: windowWidth, height: windowHeight } = useWindowSize()
watch([windowWidth, windowHeight], () => {
  updateSliceStyles()
})

const settings = computed(() => {
  if (props.slice.properties && props.slice.properties.settings)
    return props.slice.properties.settings

  return {}
})

watch(settings, () => {
  updateSliceStyles()
}, { deep: true })

const sliceRef = ref<HTMLElement | null>(null)
provide('sliceRef', sliceRef)

const clickPosition = ref({ x: 0, y: 0, active: false })
function openContextMenu(e: MouseEvent) {
  if (isTopLevelWindow()) {
    e.preventDefault()
    clickPosition.value = {
      active: true,
      x: e.clientX,
      y: e.clientY,
    }
  }
}

// Setup for highlighting the slice that is being hovered over
const sliceRoot = ref<HTMLElement | null>(null)
const highlightStyles = ref({})
const highlightedElement = computed(() => {
  return adminStore.highlightElement
})

const isCurrentlyHightlighted = computed(() => {
  return (
    highlightedElement.value.type === props.slice.type
    && highlightedElement.value.id === props.slice.id
  )
})

watch(highlightedElement, () => {
  if (isCurrentlyHightlighted.value)
    highlightStyles.value = getHighlightStyles()
})

function getHighlightStyles() {
  const styles = {
    top: 'auto',
    left: 'auto',
    width: 'auto',
    height: 'auto',
    opacity: '0.5',
  }

  if (sliceRoot.value && typeof window !== 'undefined') {
    const { top, left, width, height } = sliceRoot.value?.getBoundingClientRect()

    styles.top = `${top}px`
    styles.left = `${left}px`
    styles.width = `${width}px`
    styles.height = `${height}px`
  }

  return styles
}

// function closeContextMenu() {
//   clickPosition.value.active = false
// }

// function selectElement(element: ElementInterface) {
//   adminStore.setSelectedElement(element)
// }

// Pass this down through PageNavigationSlice to PageNavigationField
provide(
  'slice',
  computed(() => {
    return props.slice
  }),
)

// TODO: Is this necessary? It may cause confusion in devtools
provide('fields', fieldsProxy.value)
provide('mountOpen', true)

function logError(error: any) {
  let msg = error

  if (Object.prototype.hasOwnProperty.call(error, 'message'))
    msg = error.message

  if (typeof window === 'object'
    && msg.includes('Failed to fetch dynamically imported module')
  ) {
    // very likely that we released a new version so let's try to refresh
    let url = window.location.href
    const queryString = new URLSearchParams(window.location.search)

    if (!queryString.has('v-fresh')) {
      // only if fresh is not on the page
      if (url.includes('?'))
        url += '&v-fresh=1'
      else
        url += '?v-fresh=1'

      window.location.href = url
    }
  }

  useEvent('voix:error', error)

  return error
}
</script>

<template>
  <NuxtErrorBoundary @error="logError">
    <template v-if="resolvedComponent">
      <component
        :is="resolvedComponent"
        :id="slice.properties?.id || sliceId"
        ref="sliceRef"
        :slice="slice"
        :fields="fieldsProxy"
        :style="sliceStyles"
        :class="[$attrs.class]"
        @click.right="openContextMenu"
      >
        <template v-for="{ name } in sliceConfig?.slots" #[name]>
          <slot :name="name" />
        </template>
      </component>
    </template>
    <DevOnly v-else>
      The component {{ slice.component }} cannot be found. Are you sure you named it correctly?
    </DevOnly>
  </NuxtErrorBoundary>
</template>
