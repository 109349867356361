import { v4 as uuidv4 } from 'uuid'
import type {
  FieldConfigInterface,
  FieldInterface,
  SliceConfigInterface,
  SliceInterface,
} from '../types'

export class Field {
  fieldConfig: FieldConfigInterface
  fieldValue: any
  config: any
  value: any

  constructor(fieldConfig: FieldConfigInterface, value: any) {
    this.fieldConfig = fieldConfig
    this.fieldValue = value
    this.config = {}
    this.value = null
  }
}

export class Fields {
  [key: string]: Field
}

export function useSlice() {
  /*
  Builds the final value of the each field in a slice and puts it in a proxy
  to allow for some fancy magic properties and methods like ".value" automatically
  returning the value of the field independent of the type.

  This is why we require that fields have the value assigned to the field name inside
  the object. For example:

  Text field will look something like:
  {
    type: 'text',
    text: 'Hello World'
  }

  Image field will look something like:
  {
    type: 'image',
    image: {
      url: 'https://example.com/image.jpg',
      alt: 'Example Image'
    }
  }
  */
  const resolveField = (
    fieldName: string,
    fieldConfig: FieldConfigInterface,
    fieldValue: FieldInterface | undefined,
  ) => {
    // If the field doesn't have a value that means it's new and hasn't been stored
    // in the db yet. Lets create the new field using the configuration defaults
    if (!fieldValue) {
      // TODO: Maybe show this in the console with some sort of debug mode?
      // console.log(
      //   `Field doesn't have a value in db - creating data for field: "${fieldName}"`
      // )
      fieldValue = {
        id: uuidv4(),
        name: fieldName,
        type: fieldConfig.type,
        enabled: fieldConfig.enabled,
        config: fieldConfig,
        properties: [],
      }
      fieldValue[fieldConfig.type] = fieldConfig.default
    }

    // Create the class
    const fieldClass = new Field(fieldConfig, fieldValue)

    return new Proxy(fieldClass, {
      get(target, property) {
        // if the property is present return it
        if (property === 'enabled')
          return target.fieldValue.enabled

        // Attach the configuration of the field
        if (property === 'config')
          return target.fieldConfig

        // Return any property of the value if it exists
        if (property in target.fieldValue)
          return target.fieldValue[property]

        // if the property is enabled lets provide a shortcut to tell them if
        // it's enabled or not
        if (property === 'enabled') {
          if (typeof target.fieldValue.enabled == 'undefined')
            return target.fieldValue.enabled
        }

        // If the value is requested using ".value" or the type of the field like ".text" return the value
        if (property === target.fieldValue.type || property === 'value') {
          // If the field has a value return it
          if (
            target.fieldValue.type
            && typeof target.fieldValue[target.fieldValue.type] !== 'undefined'
          )
            return target.fieldValue[target.fieldValue.type]

          //  Else return the default value that was set in the configuration
          else return target.fieldConfig.default
        }

        // If we ever want the ability to run functions on the field we can do it here
        // return function () {
        //   // This function will be executed when property is accessed as a function
        // }
      },
    })
  }

  // ResolveFields provides a way for each slice to merge the configuration
  // and the fields into a single object. It is designed to use this function
  // directly in the slice. To prevent developer from having to add it each
  // time we inject it into every slice via the vite plugin.
  const resolveFields = (
    sliceConfig: SliceConfigInterface,
    sliceFields: Array<FieldInterface>,
  ) => {
    // Ensure there is a field configuration at all
    if (sliceConfig && sliceConfig.fields) {
      const voixFields = new Fields()

      for (const fieldName of Object.keys(sliceConfig.fields)) {
        const fieldConfig = sliceConfig.fields[fieldName]

        const fieldValue = sliceFields?.find(f => f.name === fieldName)

        // Add the field to the voixFields as a Proxy to assist with some fancy
        // properties and methods
        voixFields[fieldName] = resolveField(
          fieldName,
          fieldConfig,
          fieldValue,
        )
      }

      // Wrap the fields in a proxy to allow for error handling when the field does not exist
      // and accessing the final value of the field
      const fields = new Proxy(voixFields, {
        get(target, property) {
          // If the field exists on the slice return it
          if (property in target)
            return target[property]

          // @todo need to refactor this to be more intelligable
          // The field does not exist on this slice so lets warn the developer
          // const missingProperty = property.toString()
          // if (missingProperty && !missingProperty.startsWith('__v_'))
          //   console.warn(`Access to non-existent field "${missingProperty}" in "${sliceConfig.label}"`, target)
        },
      })

      return fields
    }
    return null
  }

  const getField = (
    slice: SliceInterface,
    fieldname: string,
  ): FieldInterface | null => {
    if (slice && slice.fields) {
      const field = slice.fields[fieldname]
      if (field)
        return field
    }
    return null
  }

  return {
    resolveFields,
    getField,
  }
}
