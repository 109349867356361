import type { GroupSettingsInterface } from '../types'

function requestBuildUnitStyle(style: Record<string, string | number>,
  propertyName: string,
  propertyValue: string | undefined) {
  if (propertyValue)
    style = buildUnitStyle(style, propertyName, propertyValue)

  return style
}

function buildUnitStyle(
  style: Record<string, string | number>,
  propertyName: string,
  propertyValue: string,
) {
  // See if the propertyValue is a number and if it contains only digits
  if (!Number.isNaN(Number(propertyValue))) {
    style[propertyName] = `${propertyValue}px`
  }
  else {
    if (style) {
      if (propertyValue)
        style[propertyName] = propertyValue
      else
        delete style[propertyName]
    }
  }
  return style
}

function buildColorStyles(styles: Record<string, string | number>,
  settings: GroupSettingsInterface) {
  if (settings.color)
    styles.color = settings.color

  if (settings.backgroundColor)
    styles.backgroundColor = settings.backgroundColor

  return styles
}

function buildPaddingStyles(styles: Record<string, string | number>,
  settings: GroupSettingsInterface) {
  if (settings.padding) {
    if (settings.padding.top) {
      styles = requestBuildUnitStyle(
        styles,
        'paddingTop',
        settings.padding.top?.toString(),
      )
    }
    if (settings.padding.bottom) {
      styles = requestBuildUnitStyle(
        styles,
        'paddingBottom',
        settings.padding.bottom?.toString(),
      )
    }
    if (settings.padding.left) {
      styles = requestBuildUnitStyle(
        styles,
        'paddingLeft',
        settings.padding.left?.toString(),
      )
    }
    if (settings.padding.right) {
      styles = requestBuildUnitStyle(
        styles,
        'paddingRight',
        settings.padding.right?.toString(),
      )
    }
  }

  return styles
}

function buildMarginStyles(styles: Record<string, string | number>,
  settings: GroupSettingsInterface) {
  if (settings.margin) {
    if (settings.margin.top) {
      styles = requestBuildUnitStyle(
        styles,
        'marginTop',
        settings.margin.top?.toString(),
      )
    }
    if (settings.margin.bottom) {
      styles = requestBuildUnitStyle(
        styles,
        'marginBottom',
        settings.margin.bottom?.toString(),
      )
    }
    if (settings.margin.left) {
      styles = requestBuildUnitStyle(
        styles,
        'marginLeft',
        settings.margin.left?.toString(),
      )
    }
    if (settings.margin.right) {
      styles = requestBuildUnitStyle(
        styles,
        'marginRight',
        settings.margin.right?.toString(),
      )
    }
  }

  return styles
}

function buildFlexStyles(styles: Record<string, string | number>,
  breakpointSettings: GroupSettingsInterface) {
  if (breakpointSettings.displayMode === 'flex' && breakpointSettings.flex) {
    if (!styles)
      styles = {}

    styles['flex-direction'] = breakpointSettings.flex.direction
    styles['flex-wrap'] = breakpointSettings.flex.wrap
    styles['justify-content'] = breakpointSettings.flex.justify
    styles['align-items'] = breakpointSettings.flex.align
  }

  return styles
}

function buildGridStyles(styles: Record<string, string | number>,
  breakpointSettings: GroupSettingsInterface) {
  if (breakpointSettings.displayMode === 'grid' && breakpointSettings.grid) {
    if (!styles)
      styles = {}

    styles.columns = breakpointSettings.grid.columns
    styles.gridTemplateColumns = `repeat(${styles.columns}, minmax(0, ${styles.columns}fr))`

    if (breakpointSettings.grid.unifiedGap) {
      styles = requestBuildUnitStyle(
        styles,
        'gap',
        breakpointSettings.grid.gap?.toString(),
      )
    }
    else {
      // styles.columnGap = `${breakpointSettings.gridProperties.gapX}px`
      styles = requestBuildUnitStyle(
        styles,
        'columnGap',
        breakpointSettings.grid.gapX?.toString(),
      )
      styles = requestBuildUnitStyle(
        styles,
        'rowGap',
        breakpointSettings.grid.gapY?.toString(),
      )
    }
  }

  return styles
}

function buildMaxWidthStyles(styles: Record<string, string | number>,
  breakpointSettings: GroupSettingsInterface) {
  if (breakpointSettings.maxWidth) {
    styles = requestBuildUnitStyle(
      styles,
      'maxWidth',
      breakpointSettings.maxWidth.toString(),
    )
  }
  return styles
}

export {
  requestBuildUnitStyle,
  buildUnitStyle,
  buildColorStyles,
  buildPaddingStyles,
  buildMarginStyles,
  buildFlexStyles,
  buildGridStyles,
  buildMaxWidthStyles,
}
